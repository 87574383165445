/**
 * Foundation for Sites by ZURB
 * Version 6.0.6
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Sass utilities
@import 'util/util';

// Global variables and styles
@import 'global';

// Components
@import 'grid/grid';
@import 'typography/typography';

@import 'components/visibility';
@import 'components/float';
@import 'components/button';


















@import 'components/reveal';










@mixin foundation-everything {
  @include foundation-global-styles;
  @include foundation-grid;
  @include foundation-typography;
  @include foundation-button;
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @include foundation-reveal;
  
  
  
  
  
  
  
}



@import "settings/settings"
